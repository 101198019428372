import { Expose } from 'class-transformer';

export default class EditJobError {
  start_time: string[] = []
  end_time: string[] = []
  lunch_break: string[] = []
  headcount: string[] = []
  description: string[] = []
  hygienist_required: string[] = []
  parking: string[] = []
  @Expose({ name: '_schema' }) schema: string[] = []
}