import React from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from 'components/Header'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import Alert from '@mui/material/Alert'
import { Link } from 'react-router-dom'
import { useNurse } from 'contexts/NurseProvider'
import useTakeJob from 'hooks/useTakeJob'
import Job from 'logic/Job'
import { useNavigate } from 'react-router'
import { nurseJobsURL, nurseTakeJobURL } from 'routes/urls'
import { useParams } from 'react-router'
import { DateTime } from 'luxon'
import { isEmpty } from 'lodash'
import Loading from 'components/Loading';
import { useAPI } from 'contexts/APIProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { Tooltip } from '@mui/material';
import NurseCalendarContext from './context';

const NurseJobList: React.FC = () => {
  const { roles } = React.useContext(NurseCalendarContext)
  const navigate = useNavigate()
  const params = useParams()
  const { nurse } = useNurse()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { takeJobInProgress, takeJob } = useTakeJob()
  const [jobs, setJobs] = React.useState<Job[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchJobs = React.useCallback(async () => {
    if (!nurse || params.date === undefined) return
    const date = DateTime.fromISO(params.date)
    try {
      setLoading(true)
      const jobs = await api.getNurseJobsByDate(nurse.nurse_id, date)
      setJobs(jobs)
    } catch (error) {
      console.error(error)
      showAlert('error', 'Failed to fetch jobs')
    } finally {
      setLoading(false)
    }
  }, [api, nurse, params.date, showAlert])

  const visibleJobs = React.useMemo(() => {
    return jobs.filter(job => roles.includes(job.job_role))
  }, [jobs, roles])

  React.useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  function goBack() {
    if (nurse) navigate(nurseJobsURL(nurse.nurse_id))
  }

  if (!nurse || params.date === undefined) return null

  const date = DateTime.fromISO(params.date)

  if (takeJobInProgress || loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text={date.toFormat('ccc, LLL dd')}>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      {isEmpty(visibleJobs) ? (
        <Alert severity='info'>
          No jobs posted on this date yet.
        </Alert>
      ) : (
        visibleJobs.map(job => (
          <Card key={job.job_id}>
            <CardContent sx={{
              display: 'grid',
              gridTemplateColumns: '3fr 1fr',
              gridTemplateRows: 'auto auto',
              gridTemplateAreas: `
              'title earnings'
              'chips earnings'`,
              gap: 1,
              padding: 1
            }}>
              <Box sx={{ gridArea: 'earnings', alignSelf: 'center', justifySelf: 'center', display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant='h1'>{job.estimated_earnings(nurse.paygrade_rate)}</Typography>
                <Chip variant='filled' color='primary' size='small' label={job.job_role} />
              </Box>
              <Box sx={{ gridArea: 'title' }}>
                <Typography variant='h2'>
                  {nurse.approved ? job.practice_label : 'A Practice Nearby'}
                </Typography>
              </Box>
              <Box sx={{ gridArea: 'chips', display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                <Chip variant='outlined' size='small' label={job.distance_label} />
                <Chip variant='outlined' size='small' label={job.time_label(nurse.tz)} />
                <Chip variant='outlined' size='small' label={job.lunch_break_label} />
                <Chip variant='outlined' size='small' label={job.city} />
                <Chip variant='outlined' size='small' label={job.parking_label} />
                {job.payment_duration_label ? (
                  <Tooltip title={job.payment_duration_explanation}>
                    <Chip variant='outlined' size='small' label={`Pays ${job.payment_duration_label}`} />
                  </Tooltip>
                ) : null}
              </Box>
            </CardContent>
            <CardActions sx={{ pt: 0 }}>
              <Button
                onClick={_ => takeJob(job)}
                disabled={takeJobInProgress}
                size="small">
                Take Job
              </Button>
              <Button
                component={Link}
                to={nurseTakeJobURL(nurse.nurse_id, job.job_id)}
                disabled={takeJobInProgress}
                size="small">
                More Details
              </Button>
            </CardActions>
          </Card>
        ))
      )}
    </Box >
  )
}

export default NurseJobList