import { Box, Button, Modal } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Header from 'components/Header';
import { Edit, Delete } from '@mui/icons-material';
import { useAPI } from 'contexts/APIProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import Discount from 'logic/Discount';
import React from 'react';
import { DiscountSearchForm } from 'types/interfaces';
import DiscountModal from './DiscountModal';


const AdminDiscountList: React.FC = () => {
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const [form, setForm] = React.useState<DiscountSearchForm>({ sideloads: ['practice'] })
    const [loading, setLoading] = React.useState<boolean>(false)
    const [open, setOpen] = React.useState<boolean>(false)
    const [discounts, setDiscounts] = React.useState<Discount[]>([])
    const [selectedDiscount, setSelectedDiscount] = React.useState<Discount>()

    const fetchDiscounts = React.useCallback(async () => {
        try {
            setLoading(true)
            const discounts = await api.listDiscounts(form)
            setDiscounts(discounts)
        } catch (error) {
            showAlert('error', 'Failed to fetch discounts')
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [api, form, showAlert])

    const onEdit = React.useCallback((discount: Discount) => {
        setSelectedDiscount(discount)
        setOpen(true)
    }, [setSelectedDiscount, setOpen])

    const onCreate = React.useCallback(() => {
        setSelectedDiscount(undefined)
        setOpen(true)
    }, [setSelectedDiscount, setOpen])

    const onDelete = React.useCallback(async (discount: Discount) => {
        try {
            await api.deleteDiscount(discount.id)
            await fetchDiscounts()
        } catch (error) {
            showAlert('error', 'Failed to delete discount')
            console.error(error)
        }
    }, [api, fetchDiscounts, showAlert])

    React.useEffect(() => {
        fetchDiscounts()
        // only fetch on initial page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns: GridColDef<Discount>[] = React.useMemo(() => [{
        minWidth: 200,
        headerName: 'Discount Name',
        field: 'name',
        type: 'string',
    }, {
        minWidth: 200,
        headerName: 'Practice',
        field: 'practice_name',
        type: 'string',
    }, {
        minWidth: 200,
        headerName: 'Period',
        field: 'period',
        type: 'string',
    }, {
        minWidth: 300,
        headerName: 'Dental Nurse Fee',
        field: 'discounted_fee_formatted',
        type: 'string',
    }, {
        minWidth: 300,
        headerName: 'Hygienist Fee',
        field: 'discounted_hygienist_fee_formatted',
        type: 'string',
    }, {
        minWidth: 200,
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams<Discount>) => [
            <GridActionsCellItem icon={<Edit />} onClick={() => onEdit(params.row)} label="Edit" />,
            <GridActionsCellItem icon={<Delete />} onClick={() => onDelete(params.row)} label="Delete" />,
        ]
    }], [onEdit, onDelete])

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
                <Header text='Discounts'>
                    <Button variant='contained' color='primary' onClick={onCreate}>Add Discount</Button>
                </Header>
                <DataGridPro
                    rows={discounts}
                    columns={columns}
                    getRowId={(discount) => discount.id}
                    loading={loading}
                    checkboxSelection
                    disableRowSelectionOnClick
                    hideFooter
                />
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <DiscountModal
                    discount={selectedDiscount}
                    onSuccess={async () => {
                        setOpen(false)
                        await fetchDiscounts()
                    }}
                    onCancel={() => setOpen(false)}
                />
            </Modal>
        </React.Fragment>
    )
};

export default AdminDiscountList;
