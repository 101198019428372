import { Expose } from 'class-transformer';

export default class DiscountFormError {
    practice_id: string[] = []
    name: string[] = []
    start_at: string[] = []
    end_at: string[] = []
    discounted_fees: string[] = []
    discounted_hygienist_fees: string[] = []
    @Expose({ name: '_schema' }) schema: string[] = []
}