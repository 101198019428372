import React from 'react'
import DescriptionList from 'components/DescriptionList'
import Header from 'components/Header'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import Box from '@mui/material/Box'
import { useNurse } from 'contexts/NurseProvider'
import { useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { nurseJobsURL, nurseJobListURL, nurseCancelJobEmploymentURL, nurseViewTimeSheetURL, nurseProposeTimeSheetChangeURL } from 'routes/urls'
import { useJobEmployment } from 'contexts/JobEmploymentProvider'
import useApproveTimeSheetChange from 'hooks/useApproveTimeSheetChange'
import Loading from 'components/Loading'
import { useZendesk } from 'contexts/ZendeskProvider'


const NurseViewJobEmployment: React.FC = () => {
  const { nurse } = useNurse()
  const navigate = useNavigate()
  const { openZendesk } = useZendesk()
  const { je: employment, loading: fetchingEmployment } = useJobEmployment()
  const { approveTimeSheetChange, approveTimeSheetChangeInProgress, rejectTimeSheetChange } = useApproveTimeSheetChange()

  if (!nurse || employment === undefined) return <Loading />
  if (approveTimeSheetChangeInProgress || fetchingEmployment) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Header variant='h3' text={employment.date_label(nurse.tz)}>
        <IconButton onClick={_ => navigate(nurseJobsURL(nurse.nurse_id))}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DescriptionList labelWidth='30%' valueWidth='70%' rowGap={1} data={[{
          label: 'Practice',
          value: employment.practice_label,
        }, {
          label: 'Role',
          value: employment.job_role,
        }, {
          label: 'Phone Number',
          value: employment.practice_phone_number,
        }, {
          label: 'Address',
          value: employment.practice_address,
        }, {
          label: 'Time',
          value: employment.time_label(nurse.tz),
        }, {
          label: 'Parking',
          value: employment.parking_label
        }]} />
        {employment.description ? (
          <DescriptionList labelWidth='30%' valueWidth='70%' data={[{
            label: 'Description',
            value: employment.description
          }]} />
        ) : null}
        {employment.status_alert('nurse', openZendesk)}
        {employment.fulfillment_status !== 'cancelled' ? (
          <Button
            component={RouterLink}
            variant="contained" color="primary"
            to={nurseViewTimeSheetURL(nurse.nurse_id, employment.id)}>
            View Timesheet
          </Button>
        ) : null}
        {employment.timesheet_status === 'timesheet_pending_nurse_approval' ? (
          <React.Fragment>
            <Button 
              onClick={() => approveTimeSheetChange(employment.latest_timesheet_change_id)}
              variant="contained" color="success">
              Accept Changes
            </Button>
            <Button 
              onClick={() => rejectTimeSheetChange()}
              variant="contained" color="error">
              Reject Changes
            </Button>
          </React.Fragment>
        ) : null}
        {employment.can_propose_timesheet_change ? (
          <Button
            component={RouterLink}
            variant="contained" color="primary"
            to={nurseProposeTimeSheetChangeURL(nurse.nurse_id, employment.id)}>
            Propose TimeSheet Changes
          </Button>
        ) : null}
        {employment.can_cancel ? (
          <Button
            variant="outlined"
            color="error"
            component={RouterLink}
            to={nurseCancelJobEmploymentURL(nurse.nurse_id, employment.id)}>
            Cancel Job
          </Button>
        ) : null}
        {employment.can_reapply ? (
          <Button
            component={RouterLink}
            variant="contained" color="primary"
            to={nurseJobListURL(nurse.nurse_id, employment.date_iso)}>
            Apply for another job on this date
          </Button>
        ) : null}
      </Paper>
    </Box>
  )
}

export default NurseViewJobEmployment