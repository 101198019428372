import { Type, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export default class Discount {
  id!: number
  practice_id!: number
  practice_name!: string
  practice_group_id!: number
  practice_group_name!: string
  name!: string

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  start_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  end_at!: DateTime

  discounted_fees!: number
  discounted_hygienist_fees!: number

  get period() {
    return `${this.start_at.toFormat('dd MMM yyyy')} - ${this.end_at.toFormat('dd MMM yyyy')}`
  }

  get discounted_fee_formatted() {
    if (!this.discounted_fees) return 'N/A'
    return `£${(this.discounted_fees / 100).toFixed(2)} per job (inclusive of VAT)`
  }

  get discounted_hygienist_fee_formatted() {
    if (!this.discounted_hygienist_fees) return 'N/A'
    return `£${(this.discounted_hygienist_fees / 100).toFixed(2)} per job (inclusive of VAT)`
  }
}
