import { Box, Button, InputAdornment } from '@mui/material'
import { SxProps, Theme } from '@mui/material';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import FormField from 'components/FormField';
import Discount from 'logic/Discount';
import React from 'react'
import { DiscountForm } from 'types/interfaces';
import update from 'immutability-helper'
import AutoCompleteSelectField from 'components/AutoCompleteSelectField';
import { useAPI } from 'contexts/APIProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import Practice from 'logic/Practice';
import DiscountFormError from 'errors/DiscountFormError';
import { fromCentsToPounds, fromPountsToCents } from 'logic/helpers'

const style: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['80%', '80%', '50%'],
    maxHeight: '90%',
    bgcolor: 'background.default',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 1
};

type DiscountModalProps = {
    discount?: Discount
    onSuccess: () => Promise<void>
    onCancel: () => void
}

const DiscountModal: React.FC<DiscountModalProps> = React.forwardRef(({ discount, onSuccess, onCancel }, ref) => {
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const [practices, setPractices] = React.useState<Practice[]>([])
    const [form, setForm] = React.useState<DiscountForm>({})
    const [errors, setErrors] = React.useState<DiscountFormError>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const fetchPractices = React.useCallback(async (searchTerm: string) => {
        if (!searchTerm) return
        setPractices(await api.listPractices({ name: searchTerm }))
      }, [api])

    const updateForm = React.useCallback((name: string, value: any) => {
        setForm(update(form, { [name]: { $set: value } }))
        if (errors) setErrors(update(errors, { [name]: { $set: [] } }))
    }, [form, errors])

    const practiceSelectOptions = React.useMemo(() => {
        return practices.map((practice) => ({
          value: practice.practice_id,
          label: practice.enabled ? practice.practice_name : `${practice.practice_name} (disabled)`,
        }))
    }, [practices])

    const save = React.useCallback(async () => {
        try {
            setLoading(true)
            if (discount) {
                await api.updateDiscount(discount.id, form)
            } else {
                await api.createDiscount(form)
            }
            onSuccess()
        } catch (e) {
            if (e instanceof DiscountFormError) {
                setErrors(e)
            } else {
                showAlert('error', 'Error creating discount')
                console.error('unhandled exception', e)
            }
        } finally {
            setLoading(false)
        }
    }, [api, discount, form, onSuccess, showAlert])

    React.useEffect(() => {
        if (discount) {
            setForm({
                name: discount.name,
                practice_id: discount.practice_id,
                start_at: discount.start_at.toFormat('yyyy-MM-dd'),
                end_at: discount.end_at.toFormat('yyyy-MM-dd'),
                discounted_fees: discount.discounted_fees,
                discounted_hygienist_fees: discount.discounted_hygienist_fees
            })
        }
    }, [discount])
    
    return (
        <Box sx={style}>
            <FormField 
                name='name' 
                label='Discount Name'
                helperText='Be descriptive, this will be displayed to the practice when booking a job'
                value={form.name ?? ''}
                onChange={(e) => updateForm('name', e.target.value)}
                errors={errors?.name}
            />
            { !discount && (
                <AutoCompleteSelectField
                    label='Select a practice to apply the discount to'
                    sx={{ width: '100%' }}
                    options={practiceSelectOptions}
                    fetchOptions={fetchPractices}
                    value={form.practice_id ?? ''}
                    onChange={(value) => updateForm('practice_id', value)}
                />
            )}
            <FormField 
                name='start_date' 
                label='Start Date' 
                helperText='The discount will be applied to jobs starting from this date'
                type='date' 
                InputLabelProps={{ shrink: true }} 
                value={form.start_at ?? ''}
                onChange={(e) => updateForm('start_at', e.target.value)}
                errors={errors?.start_at}
            />
            <FormField 
                name='end_date' 
                label='End Date' 
                helperText='The discount will be applied to jobs ending on/before this date'
                type='date' 
                InputLabelProps={{ shrink: true }} 
                value={form.end_at ?? ''}
                onChange={(e) => updateForm('end_at', e.target.value)}
                errors={errors?.end_at}
            />
            <FormField 
                name='discounted_fees' 
                label='Discounted Fees (Dental Nurses)' 
                type='number' 
                helperText='Total Locumloop fee for the job inclusive of VAT'
                value={fromCentsToPounds(form.discounted_fees ?? 0)}
                onChange={(e) => updateForm('discounted_fees', fromPountsToCents(e.target.value))}
                errors={errors?.discounted_fees}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CurrencyPoundIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <FormField 
                name='discounted_hygienist_fees' 
                label='Discounted Fees (Hygienists)' 
                type='number' 
                helperText='Total Locumloop fee for the job inclusive of VAT'
                value={fromCentsToPounds(form.discounted_hygienist_fees ?? 0)}
                onChange={(e) => updateForm('discounted_hygienist_fees', fromPountsToCents(e.target.value))}
                errors={errors?.discounted_hygienist_fees}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CurrencyPoundIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Button variant='contained' color='primary' onClick={save} disabled={loading}>
                {loading ? 'Please wait...' : 'Save'}
            </Button>
            <Button variant='outlined' color='primary' onClick={onCancel} disabled={loading}>
                Cancel
            </Button>
        </Box>
    )
})

export default DiscountModal