import { Box, Chip, Paper, Typography } from '@mui/material'
import TickIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingActions from '@mui/icons-material/PendingActions'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import { get } from 'lodash'
import { getDocumentTypesFromFileType, getFileTypeFromDocumentType } from 'logic/helpers'
import Nurse from 'logic/Nurse'
import NurseDocumentFile from 'logic/NurseDocumentFile'
import React from 'react'
import { DocumentFileType, DocumentType } from 'types/types'
import Control from './Control'
import { FILE_LABEL_MAPPING, DOCUMENTATIONS_LABEL_MAPPING } from 'types/constants';

type Compliance = {
    docType: DocumentType
    approved?: boolean
    date?: string
    reason: string
    file_count: number
}

export const getComplianceFromDocumentType = (docType: DocumentType, nurse: Nurse) => {
    const fileType = getFileTypeFromDocumentType(docType)
    return {
        docType: docType,
        approved: get(nurse, docType + '_approved', undefined),
        reason: get(nurse, docType + '_reason', ''),
        date: get(nurse, docType + '_date', undefined) || undefined,
        file_count: get(nurse, fileType + '_file_count', 0),
    } as Compliance
}

const COMPLIANCE_BODY_MAPPING = {
    'gdc' : 'Required for England and Scotland',
    'indemnity_insurance' : 'Required for England and Scotland',
    'hepatitis_b_vaccination' : 'Hepatitis B vaccination required for England and Scotland',
    'hepatitis_c': 'Hepatitis C antibody serology test required for Scotland only',
    'tb': 'Tuberculosis BCG vaccination / Evidence of BCG scar / results of mantoux test / TB blood test result for Scotland only',
    'hiv': 'HIV antibody serology test required for Scotland only',
    'dbs': 'Required for England only',
    'pvg': 'Required for Scotland only',
    'infection_control': 'Optional, however more jobs will be available if provided',
    'cpr': 'Optional, however more jobs will be available if provided',
    'resume': 'Required for hygienists jobs',
}

type ComplianceChipProps = {
    compliance: Compliance
    onClick: () => void
    size?: 'small' | 'medium'
}

export const ComplianceChip: React.FC<ComplianceChipProps> = ({ compliance, onClick, size = 'small' }) => {
    const icon = compliance.approved ? <TickIcon /> : <ErrorIcon />
    const label = DOCUMENTATIONS_LABEL_MAPPING[compliance.docType]
    let color: 'default' | 'success' | 'error' | 'warning' = 'default'
    if (compliance.approved === true) {
        color = 'success'
    } else if (compliance.approved === false) {
        color = 'error'
    } else if (compliance.file_count > 0) {
        color = 'warning'
    } else {
        color = 'default'
    }
    return <Chip icon={icon} label={label} variant="filled" onClick={onClick} color={color} size={size}/>
}

type DocumentUploadProps = {
    fileType: DocumentFileType
    nurseId: number
    dense?: boolean
    exclude?: DocumentType[]
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ fileType, nurseId, dense = false, exclude = [] }) => {
    const { api } = useAPI()
    const [files, setFiles] = React.useState<NurseDocumentFile[]>([])
    const [nurse, setNurse] = React.useState<Nurse>()
    const [error, setError] = React.useState<string>('')
    const [selectedCompliance, setSelectedCompliance] = React.useState<Compliance>()
    const [loading, setLoading] = React.useState<boolean>(true)

    const fetchNurse = React.useCallback(async () => {
        try {
            setLoading(true)
            setNurse(await api.getNurse(nurseId))
        } catch (e) {
            setError('Error fetching nurse')
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [api, nurseId])

    const fetchFiles = React.useCallback(async () => {
        try {
            setFiles(await api.listDocuments({ nurse_id: nurseId, doc_type: fileType }))
        } catch (e) {
            setError('Error fetching files')
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [api, fileType, nurseId])

    const reload = React.useCallback(async () => {
        await fetchFiles()
        await fetchNurse()
    }, [fetchFiles, fetchNurse])

    const compliances = React.useMemo(() => {
        if (!nurse) return []
        const relevantDocTypes = getDocumentTypesFromFileType(fileType, exclude)
        return relevantDocTypes.map(docType => getComplianceFromDocumentType(docType, nurse))
    }, [fileType, nurse])

    const body = React.useMemo(() => {
        if (error) {
            return error
        } else if (selectedCompliance) {
            const fileType = getFileTypeFromDocumentType(selectedCompliance.docType)
            const relevantDocTypes = getDocumentTypesFromFileType(fileType, exclude)
            if (selectedCompliance.approved === false) {
                return selectedCompliance.reason
            } else if (selectedCompliance.approved === true) {
                return 'Document Verified'
            } else if (files.length === 0) {
                return (
                    <>
                        Please upload file or take a picture
                        <br />
                        {COMPLIANCE_BODY_MAPPING[selectedCompliance.docType]}
                    </>
                )
            } else if (relevantDocTypes.length > 1) {
                return COMPLIANCE_BODY_MAPPING[selectedCompliance.docType]
            } else {
                return 'Our team member will verify your document and notify you when ready'
            }
        }
        return ''
    }, [error, files.length, selectedCompliance])
    
    const backgroundColor = React.useMemo(() => {
        if (error) {
            return 'error.light'
        } else if (selectedCompliance?.approved === true) {
            return 'success.light'
        } else if (selectedCompliance?.approved === false) {
            return 'error.light'
        } else if (files.length > 0) {
            return 'warning.light'
        }
        return 'background.paper'
    }, [error, files.length, selectedCompliance])
    
    const icon = React.useMemo(() => {
        if (error) {
            return <ErrorIcon fontSize='large' sx={{ color: 'error.main' }} />
        } else if (selectedCompliance?.approved === true) {
            return <TickIcon fontSize='large' sx={{ color: 'success.main' }} />
        } else if (selectedCompliance?.approved === false) {
            return <ErrorIcon fontSize='large' sx={{ color: 'error.main' }} />
        }
        return <PendingActions fontSize='large' sx={{ color: 'black' }} />
    }, [error, selectedCompliance])

    React.useEffect(() => {
        fetchNurse()
    }, [fetchNurse])

    React.useEffect(() => {
        fetchFiles()
    }, [fetchFiles])

    React.useEffect(() => {
        if (compliances.length === 0) return
        setSelectedCompliance(compliances[0])
    }, [compliances])

    if (!nurse) return <Loading />
    
    return (
        <Paper sx={{
            padding: 1,
            display: 'grid',
            gridTemplateColumns: ['1fr 4fr', '1fr 9fr'],
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
          "icon title"
          "icon content"
          "icon buttons"`,
            backgroundColor: backgroundColor,
            gap: [1, 0]
        }}>
            <Box sx={{ gridArea: 'icon', justifySelf: 'center', alignSelf: 'center' }}>
                {icon}
            </Box>
            <Box sx={{ 
                gridArea: 'title', 
                display: 'flex', 
                flexDirection: dense ? 'column' : ['column', 'column', 'row'], 
                gap: dense ? 0 : [0, 0, 1] }}
            >
                <Typography variant='h5' sx={{ flexGrow: [1, 1, 0]}}>{FILE_LABEL_MAPPING[fileType]}</Typography>
                {compliances.length > 1 ? (
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {compliances.map(compliance => (
                            <ComplianceChip 
                                key={compliance.docType} 
                                compliance={compliance} 
                                onClick={() => setSelectedCompliance(compliance)} 
                            />
                        ))}
                    </Box>
                ) : null}
            </Box>
            <Box sx={{ gridArea: 'content' }}>
                {loading ? <Loading /> : <Typography variant='body2'>{body}</Typography>}
            </Box>
            <Box sx={{ gridArea: 'buttons', display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                {files?.map(file => (
                    <Control 
                        key={file.id} 
                        file={file} 
                        fileCount={files.length} 
                        nurse={nurse} 
                        fileType={fileType} 
                        onSuccess={reload} 
                        onError={setError} 
                    />
                ))}
                <Control 
                    fileCount={files?.length ?? 0} 
                    nurse={nurse} 
                    fileType={fileType} 
                    onSuccess={reload} 
                    onError={setError} 
                />
            </Box>
        </Paper>
    )
}

export default DocumentUpload