import React from 'react'
import { Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useAuthUser } from 'contexts/AuthUserProvider'
import { loginURL } from 'routes/urls'
import Loading from 'components/Loading'

const Logout: React.FC = () => {
  const { authUser, logout } = useAuthUser()

  if (authUser === undefined) {
    return <Loading />
  } else if (authUser === null) {
    return <Navigate replace to={loginURL()} />
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" onClick={logout}>Logout</Button>
      </Box>
    )
  }
}

export default Logout